<template>
  <div class="excessivePage">
    <PatientTerminal v-if="!isHaveCard" />
    <HaveHealthCard :healthInfoList="healthInfoList" v-if="isHaveCard" />
  </div>
</template>

<script>
import { checkHealthCard, healthPatientInfo } from '@/network/service'
export default {
  name: 'excessivePage',
  data() {
    return {
      isHaveCard: true,
      healthInfoList: [],
    }
  },
  components: {
    PatientTerminal: () => import('./patientTerminal.vue'),
    HaveHealthCard: () => import('./haveHealthCard.vue'),
  },
  mounted() {
    this.whetherApplyCard()
  },
  methods: {
    async whetherApplyCard() {
      let res = await checkHealthCard({
        id_no: this.$Base64.decode(this.$store.state.cardList[0].idNo),
      })
      if (res.code == 0) {
        this.getHealthPatientInfo()
      } else if (res.code == -1 && res.msg.includes('未注册')) {
        this.isHaveCard = false
      } else {
        this.$toast(res.msg)
      }
    },
    async getHealthPatientInfo() {
      let res = await healthPatientInfo({
        card_no: this.$Base64.decode(this.$store.state.cardList[0].idNo),
        card_type: 2,
      })
      if (res.code == 0) {
        this.healthInfoList = res.data.root.body.detail
        this.isHaveCard = true
      } else {
        this.$toast(res.msg)
      }
    },
  },
}
</script>

<style></style>
